@use '../vars'

=heading
	line-height: 1.13
	font-family: vars.$cooperSansStack
	font-weight: 400
	margin-top: unset

	@media not all and (min-width: vars.$break48)
		br
			display: none

=heading1
	+heading
	font-size: 3.125rem

	@media (min-width: vars.$break30)
		font-size: 3.75rem

	@media (min-width: vars.$break48)
		font-size: 5rem

=heading2
	+heading
	font-size: 2.5rem

	@media (min-width: vars.$break48)
		font-size: 3.75rem
