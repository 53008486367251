@use 'common'

@font-face
	font-family: common.$cooper
	src: url('/fonts/cooperbt-light-webfont.woff2') format('woff2'), url('/fonts/cooperbt-light-webfont.woff') format('woff')
	font-weight: normal
	font-style: normal

@font-face
	font-family: common.$satoshi
	src: url('/fonts/satoshi-bold-webfont.woff2') format('woff2'), url('/fonts/satoshi-bold-webfont.woff') format('woff')
	font-weight: 700
	font-style: normal

html
	height: -webkit-fill-available

body
	+common.text
	overflow-y: scroll
	min-height: 100vh
	min-height: -webkit-fill-available
	display: grid
	grid-template-rows: 1fr
	color: common.$dark
	padding: 0
	margin: 0
	font-family: common.$satoshiSerifStack
	background-color: common.$light

#__next
	display: contents

a
	color: inherit
	text-decoration: none

h1
	+common.heading1

*
	box-sizing: border-box

:root
	--global-horizontal-spacing: 2rem

	--content-width-normal: #{common.$contentWidth-normal}
	--content-width-wide: #{common.$contentWidth-wide}
	--content-top-spacing: 2.5rem
	--content-gap-small: 1.875rem
	--box-padding: 1.5rem
	--box-spacing: 1.25rem

	@media (min-width: common.$break37)
		--box-padding: 2.1875rem

	@media (min-width: common.$break48)
		--box-spacing: 2.5rem
		--content-top-spacing: 7rem
		--content-gap-small: 2.8125rem
