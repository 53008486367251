@use '../vars'

=text
	font-size: 1.125rem
	line-height: 1.6

	@media (min-width: vars.$break30)
		font-size: 1.25rem

=textSmall
	font-size: 1rem
	line-height: 1.5

	@media (min-width: vars.$break30)
		font-size: 1.125rem

=leadParagraph($isLineHeightBig: false)
	font-size: 1.25rem

	@media (min-width: vars.$break30)
		font-size: 1.5rem

	@if $isLineHeightBig
		line-height: 1.583

	@else
		line-height: 1.6
