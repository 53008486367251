$blue: #5C75F9
$grey: #F4F3F3
$dark: #333333
$red: #D43C3C
$light: #FBFBFB
$light2: #EAEAEA
$offWhite300: #E6E5E3

$sansSerifStack: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif

$cooper: cooper
$satoshi: satoshi

$cooperSansStack: $cooper, $sansSerifStack
$satoshiSerifStack: $satoshi, $sansSerifStack

$break30: 30rem
$break37: 37rem
$break48: 48rem
$break62: 62rem
$break75: 75rem

$contentWidth-normal: 68.75rem // 68.75rem == 1100px
$contentWidth-wide: 100rem // 100rem == 1600px
$contentTopSpacing: 1rem

$mobileHeaderHeight: 3.25rem
